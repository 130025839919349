/* styles.css */

/* Navigation Bar */
.navbar {
    background-color: #374151; /* Dark background color */
  }
  
  /* Container for flex layout */
  .navbar-container {
    max-width: 1200px; /* Maximum width of the container */
    margin: 0 auto; /* Center the container horizontally */
    display: flex;
    justify-content: space-between; /* Space items evenly */
    align-items: center; /* Center items vertically */
    padding: 10px 20px; /* Padding inside the container */
  }
  
  /* Social Icons */
  .social-icons {
    display: flex; /* Display social icons inline */
  }
  
  /* Mobile Menu Button */
  .mobile-menu-button {
    display: none; /* Hide mobile menu button by default */
  }
  
  /* Mobile Menu - Show when mobileMenuOpen is true */
  .mobile-menu {
    display: none; /* Hide mobile menu by default */
  }
  
  /* Apply styles to mobile menu when mobileMenuOpen is true */
  .mobile-menu.open {
    display: block; /* Show mobile menu */
  }
  
  /* Navigation Links */
  .nav-links {
    display: flex;
    list-style-type: none; /* Remove bullet points */
  }
  
  .nav-links li {
    margin-right: 10px; /* Spacing between list items */
  }
  
  .nav-link {
    padding: 10px 15px; /* Padding inside navigation links */
    background-color: #ddd; /* Background color of links */
    color: #333; /* Text color of links */
    border-radius: 5px; /* Rounded corners */
    text-decoration: none; /* Remove underline */
  }
  
  .nav-link:hover {
    background-color: #ccc; /* Background color on hover */
  }
  
  /* Active Navigation Link */
  .nav-link.active {
    background-color: #fff; /* Background color for active link */
    color: #007bff; /* Text color for active link */
  }
  